import { backendURL, genericResponseHandler } from ".";
interface RouteVideoFile {
  _id: string; //is just <startingRegionID>-<endingRegionID> no real need for database
  videoFile: File;
}
export type { RouteVideoFile };
export async function postVideo(file: RouteVideoFile) {
  const videoFileData = new FormData();
  console.log(file);
  videoFileData.append("path", file._id);
  videoFileData.append("videoFile", file.videoFile);
  const response = await fetch(`${backendURL}/video`, {
    method: "POST",
    body: videoFileData,
    // headers: {
    //   "Content-Type": `multipart/form-data;}`,
    // },
  });
  return await genericResponseHandler(response);
}
export async function getVideo(name: string) {
  try {
    const response = await fetch(`${backendURL}/video?name=${name}`);
    const data = await genericResponseHandler(response);
    return data;
  } catch (e) {
    console.error(JSON.stringify(e));
    return e;
  }
}
