import React, { useState, useEffect, useReducer } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { RouteVideoFile, postVideo } from "../api/routevideofile";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Region, deleteRegion, disconnectWithRegion } from "../api/region";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  addNewRegion,
  connectToRegion,
  getAllRegionsInLevel,
  getAllRegions,
} from "../api/region";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Store,
  addStoreToRegion,
  deleteStore,
  getAllStoresInLevel,
} from "../api/store";
import { LabeledFloorPlan } from "../Pages/LabelImage";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { createPortal } from "react-dom";
import { RouteVideo, getRouteVideo, updateRouteVideo } from "../api/routevideo";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { VideoFile } from "@mui/icons-material";
import RenderHostedVideo from "./VideoForRoute";
const columns = [
  { id: "empty", label: "", minWidth: 170 },
  { id: "RegionName", label: "Region Name", minWidth: 170 },
  { id: "Connected", label: "Connected Regions", minWidth: 100 },
  { id: "Store", label: "Stores in Region", minWidth: 100 },
];

interface RegionLabelerTableProps {
  levelName: string;
  levelOID: string;
  levels: Array<LabeledFloorPlan>;
}
interface RegionLabelerTableRowProps {
  regionName: string;
  regionOID: string;
  levelName: string;
  levelOID: string;
  connectedTo: Array<string>;
  otherRegions: Array<any>;
  storesInRegion: Array<string>;
  storesInLevel: Array<Store>;
  levels: Array<LabeledFloorPlan>;
  refreshLevelData: () => void;
  refreshStoreData: () => void;
}

interface OtherRegionButtonProps {
  regionName: string;
  regionOID: string;
  connected: boolean;
  onClick: () => void;
}
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const RouteVideoChip = ({
  currentRegion,
  connectedRegion,
}: {
  currentRegion: Region;
  connectedRegion: Region;
}) => {
  const [routeVideo, setRouteVideo] = useState<RouteVideo>();
  const [open, setOpen] = useState(false);
  const [chipColor, setChipColor] = useState<
    "primary" | "error" | "success" | "default"
  >("error");
  useEffect(() => {
    if (routeVideo) {
      if (routeVideo.crossLevel) {
        setChipColor("default");
      } else if (
        routeVideo.videoURL == null ||
        routeVideo.videoURL.length === 0
      ) {
        setChipColor("error");
      } else {
        if (routeVideo.transitionTimingInSeconds !== undefined) {
          setChipColor("success");
        } else {
          setChipColor("primary");
        }
      }
    }
  }, [routeVideo]);
  useEffect(() => {
    getRouteVideo(currentRegion, connectedRegion)
      .then((data) => setRouteVideo(data))
      .catch((err) => {
        //Dev Strict mode causes 2 render cycles therefore API is called twice
        //This causes the backend to throw an error as an unique index where startingRegion and endingRegion compound index is unique
        //Therefore just throw an error
        console.error(err);
      });
    // Need to have these values as dependencies as need to update route video when currentRegion or connectedRegion changes
    //MY MAN SPENT 6 HOURS ON THIS BUG SONNA VICH
  }, [currentRegion, connectedRegion]);
  const handleUpdateRouteVideo = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (routeVideo) {
      updateRouteVideo(
        routeVideo.startingRegion,
        routeVideo.endingRegion,
        routeVideo.videoURL,
        routeVideo.transitionTimingInSeconds,
        routeVideo.goingToElevatorTimingInSeconds,
        routeVideo.exitElevatorTimingInSeconds
      ).then((data) => {
        if ("message" in data) {
          if (data.message === "Success") {
            setOpen(false);
          }
        }
      });
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      item
      key={`${currentRegion._id}-connected-to-${connectedRegion._id}-grid-item`}
      xs={2}
    >
      <Tooltip
        title={
          routeVideo?.crossLevel ? (
            <Typography>Cross Level No Need Video</Typography>
          ) : (
            <>
              {routeVideo?.videoURL === undefined && (
                <Typography>Missing Video URL</Typography>
              )}
              {routeVideo?.transitionTimingInSeconds === undefined && (
                <Typography>Missing Transition Timing</Typography>
              )}
            </>
          )
        }
      >
        <Chip
          key={`${currentRegion._id}-connected-to-${connectedRegion._id}-chip`}
          label={`${currentRegion.name}>${connectedRegion.name}`}
          color={chipColor}
          onClick={() => {
            if (!routeVideo?.crossLevel) {
              handleClickOpen();
            }
          }}
        />
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Route Video</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Route Video for {currentRegion.name} connecting to{" "}
            {connectedRegion.name}
            <br />
            Upload a video
            <br />
            For URL field, please enter a youtube video URL (used for fallback)
            <br />
            For Transition field, enter the point in the video where the
            transition occur
            <br />
            Transitions refer to the point in the video where the person is
            about to enter another region
          </DialogContentText>
          <Button
            component="label"
            id="upload-video-button"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <VisuallyHiddenInput
              id="upload-video-input"
              type="file"
              accept="video/mp4"
              onChange={(event) => {
                if (routeVideo && event.target.files) {
                  postVideo({
                    _id: `${routeVideo.startingRegion}-${routeVideo.endingRegion}`,
                    videoFile: event.target.files[0],
                  });
                  setRouteVideo({
                    ...routeVideo,
                    transitionTimingInSeconds:
                      parseInt(
                        event.target.files[0].name.split(".")[0].split(" - ")[2]
                      ) || undefined,
                  });
                }
              }}
            />
          </Button>
          {routeVideo ? (
            <RenderHostedVideo
              name={`${routeVideo.startingRegion}-${routeVideo.endingRegion}`}
            />
          ) : null}
          <TextField
            autoFocus
            margin="dense"
            id="input-field"
            label="URL(fallback)"
            placeholder={"Youtube Video URL"}
            type="text"
            fullWidth
            value={routeVideo?.videoURL || ""}
            variant="standard"
            onChange={(event) => {
              if (routeVideo) {
                setRouteVideo({ ...routeVideo, videoURL: event.target.value });
              }
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="input-field"
            label="Region Transition"
            placeholder="Seconds"
            type="text"
            fullWidth
            variant="standard"
            value={routeVideo?.transitionTimingInSeconds || ""}
            onChange={(event) => {
              if (
                routeVideo &&
                event.target.value !== "0" &&
                event.target.value != null
              ) {
                setRouteVideo({
                  ...routeVideo,
                  transitionTimingInSeconds:
                    parseInt(event.target.value) || undefined,
                });
              }
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="input-field"
            label="Entering Elevator Transition"
            placeholder="Seconds"
            type="text"
            fullWidth
            variant="standard"
            value={routeVideo?.goingToElevatorTimingInSeconds || ""}
            onChange={(event) => {
              if (
                routeVideo &&
                event.target.value !== "0" &&
                event.target.value != null
              ) {
                setRouteVideo({
                  ...routeVideo,
                  goingToElevatorTimingInSeconds:
                    parseInt(event.target.value) || undefined,
                });
              }
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="input-field"
            label="Exiting Elevator Transition"
            placeholder="Seconds"
            type="text"
            fullWidth
            variant="standard"
            value={routeVideo?.exitElevatorTimingInSeconds || ""}
            onChange={(event) => {
              if (
                routeVideo &&
                event.target.value !== "0" &&
                event.target.value != null
              ) {
                setRouteVideo({
                  ...routeVideo,
                  exitElevatorTimingInSeconds:
                    parseInt(event.target.value) || undefined,
                });
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => {
              if (routeVideo) {
                setRouteVideo({
                  ...routeVideo,
                  transitionTimingInSeconds: undefined,
                  goingToElevatorTimingInSeconds: undefined,
                  exitElevatorTimingInSeconds: undefined,
                  videoURL: undefined,
                });
              }
              handleUpdateRouteVideo(event);
            }}
          >
            Clear
          </Button>
          <Button
            key={`update-button-for-${currentRegion._id}-to-${connectedRegion._id}`}
            id={`update-button-for-${currentRegion._id}-to-${connectedRegion._id}`}
            onClick={handleUpdateRouteVideo}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const OtherRegionButton = ({
  regionName,
  regionOID,
  connected,
  onClick,
}: OtherRegionButtonProps) => {
  return (
    <Button
      key={regionOID}
      variant="contained"
      color={connected ? "success" : "error"}
      onClick={onClick}
    >
      {regionName}
    </Button>
  );
};
const StoreChip = ({
  unitID,
  unit,
  refreshLevelData,
  refreshStoreData,
}: {
  unitID: string;
  unit: string;
  refreshLevelData: () => void;
  refreshStoreData: () => void;
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Grid item xs={2} key={unitID}>
      <Box
        sx={{ width: 1 }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onMouseEnter={() => {
          setIsHover(true);
        }}
      >
        <Chip
          onClick={(event) => {
            event.preventDefault();
            deleteStore(unitID).then(() => {
              refreshLevelData();
              refreshStoreData();
            });
          }}
          color={isHover ? "error" : "default"}
          label={<IconButton>{isHover ? <CloseIcon /> : unit}</IconButton>}
        />
      </Box>
    </Grid>
  );
};
const RegionLabelerTableRow = ({
  regionName,
  regionOID,
  levelName,
  levelOID,
  connectedTo,
  otherRegions,
  storesInRegion,
  storesInLevel,
  levels,
  refreshLevelData,
  refreshStoreData,
}: RegionLabelerTableRowProps) => {
  const [open, setOpen] = useState(false);
  const [storeUnitInput, setStoreUnitInput] = useState("");
  const [selectedLevel, setSelectedLevel] = useState({
    name: levelName,
    _id: levelOID,
  });
  const [regionsInSelectedLevel, setRegionsInSelectedLevel] =
    useState(otherRegions);
  useEffect(() => {
    getAllRegionsInLevel(selectedLevel._id).then((data) => {
      const filteredRegions = data.filter(
        (regionInLevel) => regionInLevel._id !== regionOID
      );
      setRegionsInSelectedLevel(filteredRegions);
    });
  }, [selectedLevel, levelOID, otherRegions]);
  const onConnectRegion = (connectRegionOID: string, connected: boolean) => {
    if (connected) {
      disconnectWithRegion(regionOID, connectRegionOID).then(() =>
        refreshLevelData()
      );
    } else {
      connectToRegion(regionOID, connectRegionOID).then(() =>
        refreshLevelData()
      );
    }
  };
  const handleAddStore = () => {
    addStoreToRegion(levelOID, regionOID, storeUnitInput).then(() => {
      refreshLevelData();
      refreshStoreData();
    });
    setStoreUnitInput("");
  };
  const onClickAway = () => {
    setOpen(false);
  };
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} key={regionOID}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {regionName}
          <IconButton
            color="error"
            onClick={() => {
              deleteRegion(regionOID).then(() => refreshLevelData());
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {
            `${connectedTo.length} Regions` /* {connectedTo
          .map((connectedRegionID) => {
            const region = otherRegions.find(
              (region) => region._id === connectedRegionID
            );
            if (region == null) {
              return null;
            } else {
              return region.name;
            }
          })
          .join(" | ")} */
          }
        </TableCell>
        <TableCell>{`${storesInRegion.length} Stores`}</TableCell>
      </TableRow>

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={onClickAway}
      >
        <TableRow key={`${regionOID}-expandablerow`}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Connect To
                </Typography>
                <Grid container>
                  {levels.map((level) => (
                    <Grid
                      item
                      xs={2}
                      key={`${level._id}-select-level-regions-for-region-${regionOID}`}
                    >
                      <Chip
                        key={`${level._id}-select-level-regions-chip`}
                        label={level.name}
                        color={
                          level.name === selectedLevel.name
                            ? "primary"
                            : "default"
                        }
                        onClick={() =>
                          setSelectedLevel({ _id: level._id, name: level.name })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
                <Divider sx={{ marginY: 2 }}>
                  <Chip color="info" label={selectedLevel.name} />
                </Divider>
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                  {regionsInSelectedLevel.map((regionDetails) => {
                    const connected = connectedTo.some(
                      (connectedRegionOID) =>
                        connectedRegionOID === regionDetails._id
                    );
                    return (
                      <Grid item xs={2} key={regionDetails._id}>
                        <OtherRegionButton
                          regionName={regionDetails.name}
                          regionOID={regionDetails._id}
                          onClick={() =>
                            onConnectRegion(regionDetails._id, connected)
                          }
                          connected={connected}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Divider />
                <Typography variant="h6" gutterBottom component="div">
                  Stores in Region
                </Typography>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                  <InputLabel htmlFor="add-new-store-in-region">
                    Store Unit/Name
                  </InputLabel>
                  <FilledInput
                    id="add-new-store-in-region"
                    type="text"
                    value={storeUnitInput}
                    onChange={(event) => setStoreUnitInput(event.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") handleAddStore();
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleAddStore}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {/* TODO: Add if have time lmao */}
                {/* <Autocomplete
              id={`${regionOID}-add-stores-input`}
              freeSolo
              options={top100Films.map((option) => option.title)}
              renderInput={(params) => <TextField {...params} label="freeSolo" />}
            /> */}
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                  {storesInRegion.map((storeInRegionOID) => {
                    const storeInRegion = storesInLevel.find(
                      (storeInLevel) => storeInRegionOID === storeInLevel._id
                    );
                    if (storeInRegion == null) return null;
                    return (
                      <StoreChip
                        key={`${storeInRegionOID}-connected-to-current-region`}
                        unitID={storeInRegionOID}
                        unit={storeInRegion.unit}
                        refreshLevelData={refreshLevelData}
                        refreshStoreData={refreshStoreData}
                      />
                    );
                  })}
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </ClickAwayListener>
    </>
  );
};

export default function RegionLabelerTable({
  levelName,
  levelOID,
  levels,
}: RegionLabelerTableProps) {
  const allRoutesElement = document.getElementById("all-routes-in-level");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [newRegionName, setNewRegionName] = useState("");
  const [addRegionColor, setAddRegionColor] = useState<
    "error" | "primary" | "success"
  >("primary");
  const [regionsInLevel, setRegionsInLevel] = useState<Array<Region>>([]);
  const [storesInLevel, setStoresInLevel] = useState<Array<Store>>([]);
  const [allRegions, setAllRegions] = useState<Array<Region>>([]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleAddRegion = async () => {
    try {
      await addNewRegion(newRegionName, levelOID);
      setAddRegionColor("success");
      setTimeout(() => {
        setAddRegionColor("primary");
      }, 1000);
      setNewRegionName("");
      getAllRegionsInLevel(levelOID).then((data) =>
        setRegionsInLevel(
          _.orderBy(
            data,
            [({ name: { length } }) => length, "name"],
            ["asc", "asc"]
          )
        )
      );
      getAllRegions().then((data) => setAllRegions(data));
    } catch (err) {
      setAddRegionColor("error");
      setTimeout(() => {
        setAddRegionColor("primary");
      }, 1000);
    }
  };
  const refreshLevelData = () => {
    getAllRegionsInLevel(levelOID).then((data) =>
      setRegionsInLevel(
        _.orderBy(
          data,
          [({ name: { length } }) => length, "name"],
          ["asc", "asc"]
        )
      )
    );
    getAllRegions().then((data) => setAllRegions(data));
  };
  const refreshStoreData = () => {
    getAllStoresInLevel(levelOID).then((data) => setStoresInLevel(data));
  };
  useEffect(() => {
    refreshLevelData();
    refreshStoreData();
    setPage(0);
  }, [levelOID]);
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  {levelName}
                </TableCell>
                <TableCell align="center" colSpan={2}>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                    <InputLabel htmlFor="add-new-region">
                      Region Name
                    </InputLabel>
                    <FilledInput
                      id="add-new-region"
                      type="text"
                      value={newRegionName}
                      color={addRegionColor}
                      onChange={(event) => setNewRegionName(event.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") handleAddRegion();
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleAddRegion}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ top: 105, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {regionsInLevel
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((currentRegion) => (
                  <RegionLabelerTableRow
                    regionName={currentRegion.name}
                    regionOID={currentRegion._id}
                    levelName={currentRegion.level.name}
                    levelOID={currentRegion.level._id}
                    connectedTo={currentRegion.connectedTo}
                    otherRegions={regionsInLevel.filter(
                      (regionInLevel) => regionInLevel._id !== currentRegion._id
                    )}
                    key={currentRegion._id}
                    levels={levels}
                    storesInRegion={currentRegion.stores}
                    storesInLevel={storesInLevel}
                    refreshLevelData={refreshLevelData}
                    refreshStoreData={refreshStoreData}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 30]}
          component="div"
          count={regionsInLevel.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {allRoutesElement != null &&
        createPortal(
          <Paper sx={{ width: "80%", overflow: "hidden", my: 2, p: 2 }}>
            <Typography variant="h4">
              All Possible Routes for {levelName}
            </Typography>
            <Typography variant="h6">
              Found{" "}
              {regionsInLevel.reduce(
                (totalRoutes, currentRegion) =>
                  totalRoutes + currentRegion.connectedTo.length,
                0
              )}{" "}
              possible routes
            </Typography>
            <br />
            <Grid container spacing={1}>
              {regionsInLevel.map((region) => {
                return region.connectedTo.map((connectedRegionID) => {
                  const connectedRegion = regionsInLevel.find(
                    (region) => region._id === connectedRegionID
                  );
                  if (connectedRegion == null) {
                    //Optimisation
                    const connectedRegionDeepSearch = allRegions.find(
                      (region) => region._id === connectedRegionID
                    );
                    if (connectedRegionDeepSearch == null) return null;
                    return (
                      <RouteVideoChip
                        currentRegion={region}
                        connectedRegion={connectedRegionDeepSearch}
                      />
                    );
                  }
                  return (
                    <RouteVideoChip
                      currentRegion={region}
                      connectedRegion={connectedRegion}
                    />
                  );
                });
              })}
            </Grid>
          </Paper>,
          allRoutesElement
        )}
    </>
  );
}
