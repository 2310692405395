import { useEffect, useRef, useState } from "react";
import { getVideo } from "../api/routevideofile";
import ReactPlayer from "react-player";
import { backendURL } from "../api";
import { transcode } from "buffer";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
interface RenderHostedVideoProps {
  name: string;
  transition?: number;
  enteringElevatorTransition?: number;
  exitingElevatorTransition?: number;
  setParentVideoFound?: (data: boolean) => void;
}
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
export default function RenderHostedVideo(props: RenderHostedVideoProps) {
  const {
    name,
    transition,
    enteringElevatorTransition,
    exitingElevatorTransition,
    setParentVideoFound,
  } = props;
  const [videoIsHosted, setVideoIsHosted] = useState<Boolean>(false);
  const videoSRC = `${backendURL}/video?name=${name}${
    transition ? `#t=${transition}` : ""
  }`;
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    getVideo(name).then((res) => {
      console.log(res);
      if (res.message === "Internal Server Error") {
        setVideoIsHosted(false);
        if (!(setParentVideoFound === undefined)) setParentVideoFound(false);
      } else if (res.message === "Video name not found in query") {
        console.log("Video name not found in query");
      } else if (res.message === "Video does not exist") {
        setVideoIsHosted(false);
        if (!(setParentVideoFound === undefined)) setParentVideoFound(false);
      } else {
        setVideoIsHosted(true);
        if (!(setParentVideoFound === undefined)) setParentVideoFound(true);
      }
    });
  }, []);
  useEffect(() => {
    if (videoRef.current) {
      if (videoRef.current) {
        videoRef.current.defaultMuted = true;
        videoRef.current.autoplay = true;
        videoRef.current.playsInline = true;
        if (transition) {
          videoRef.current.currentTime = transition;
        }
      }
    }
  }, [videoIsHosted]);

  return (
    <div id={`${name}-renderhostedvideo`}>
      {videoIsHosted ? (
        <video
          onError={(err) => {
            alert(JSON.stringify(err, getCircularReplacer()));
          }}
          ref={videoRef}
          controls
          muted
          autoPlay
          playsInline
          id={`${name}-renderhostedvideoplayer`}
          width="80%"
          src={videoSRC}
          onTimeUpdate={() => {
            if (enteringElevatorTransition) {
              //if time goes beyond the elevator point go back to elevator point
              if (videoRef.current) {
                if (videoRef.current.currentTime > enteringElevatorTransition) {
                  videoRef.current.currentTime = enteringElevatorTransition;
                  videoRef.current.pause();
                }
              }
            } else if (exitingElevatorTransition) {
              //If time is before the exit go to exit place
              if (videoRef.current) {
                if (videoRef.current.currentTime < exitingElevatorTransition) {
                  videoRef.current.currentTime = exitingElevatorTransition;
                  videoRef.current.play();
                }
              }
            }
          }}
          onSeeked={() => {
            if (videoRef.current?.currentTime && transition) {
              if (
                videoRef.current.currentTime < transition &&
                transition !== 0
              ) {
                videoRef.current.currentTime = transition;
              }
            }
            if (videoRef.current?.currentTime && enteringElevatorTransition) {
              if (
                videoRef.current.currentTime > enteringElevatorTransition &&
                enteringElevatorTransition !== 0
              ) {
                videoRef.current.currentTime = enteringElevatorTransition;
                videoRef.current.pause();
              }
            }
            if (videoRef.current?.currentTime && exitingElevatorTransition) {
              if (
                videoRef.current.currentTime < exitingElevatorTransition &&
                exitingElevatorTransition !== 0
              ) {
                videoRef.current.currentTime = exitingElevatorTransition;
                videoRef.current.play();
              }
            }
          }}
        />
      ) : (
        <CircularProgress size="10rem" />
      )}
    </div>
  );
}
