import { backendURL, genericResponseHandler } from ".";
import { Region } from "./region";
interface RouteVideo {
  startingRegion: string;
  endingRegion: string;
  crossLevel?: boolean;
  videoURL?: string;
  transitionTimingInSeconds?: number;
  goingToElevatorTimingInSeconds?: number;
  exitElevatorTimingInSeconds?: number;
}

export type { RouteVideo };
export async function getRouteVideo(
  startingRegion: Region,
  endingRegion: Region
): Promise<RouteVideo | undefined> {
  //Temporary commenteed out to resolve conflicts will solve it self after routevideos json is updated properly or even removed in the future
  const response = await fetch(
    `${backendURL}/routevideo?startingRegionID=${startingRegion._id}&endingRegionID=${endingRegion._id}`
  );
  if (response.ok) {
    const data: RouteVideo = await response.json();
    if ("message" in data && data.message === "RouteVideo not found") {
      return await createNewRouteVideo(startingRegion, endingRegion);
    }
    return data;
  }
}
export async function createNewRouteVideo(
  startingRegion: Region,
  endingRegion: Region,
  videoURL?: string,
  transitionTimingInSeconds?: number,
  goingToElevatorTimingInSeconds?: number,
  exitElevatorTimingInSeconds?: number
) {
  return await genericResponseHandler(
    await fetch(`${backendURL}/routevideo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startingRegion,
        endingRegion,
        videoURL,
        transitionTimingInSeconds,
        goingToElevatorTimingInSeconds,
        exitElevatorTimingInSeconds,
      }),
    })
  );
}

export async function updateRouteVideo(
  startingRegion: string,
  endingRegion: string,
  videoURL?: string,
  transitionTimingInSeconds?: number,
  goingToElevatorTimingInSeconds?: number,
  exitElevatorTimingInSeconds?: number
) {
  return await genericResponseHandler(
    await fetch(`${backendURL}/routevideo`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startingRegion,
        endingRegion,
        videoURL,
        transitionTimingInSeconds,
        goingToElevatorTimingInSeconds,
        exitElevatorTimingInSeconds,
      }),
    })
  );
}
